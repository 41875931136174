import React, { ReactElement, FC, useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import { useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import Box, { BoxProps } from '@material-ui/core/Box';
import { Typography, Dialog, ShipmentAddressAccordion, Chip } from 'components';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { isObjectEmpty } from 'shared/functions/isObjectEmpty';
import {
  ShipmentAddressAlternativeType,
  ShipmentAddressAlternativeUseType,
  ShipmentAddressType,
} from 'shared/types/shipments/shipments';
import { emptyLoadingContact } from 'shared/constants/shipments/shipmentAddress';
import { LoadingContact } from 'shared/types/loadingContact';
import { useNewShipmentValidation } from 'shared/hooks/useNewShipmentValidation';
import { useFormikError } from 'shared/hooks/useFormikError';
import { AutomaticPricesState, NewShipmentContext } from 'scenes/NewShipment/NewShipment';
import clsx from 'clsx';
import { Address } from 'shared/types/addressBook/addressBook';
import { useCommonStyles } from 'shared/styles/common';
import { generateKey } from 'shared/functions/generateKey';
import ShipmentAddressFields from './ShipmentAddressFields';

export type AddressAutcompleteContextData = () => void;

type ShipmentAddressEditableProps = {
  type: ShipmentAddressType;
  isPriceRequest?: boolean;
  isTemplate?: boolean;
  offices: Address[];
} & BoxProps &
  BaseProps;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const dummyFunction = () => {};

export const AddressAutocompleteContext = React.createContext<AddressAutcompleteContextData>(
  dummyFunction,
);

const ShipmentAddressEditable: FC<ShipmentAddressEditableProps> = ({
  type,
  isPriceRequest = false,
  isTemplate = false,
  offices,
  ...restProps
}): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formik = useFormikContext<any>();
  const isError = useFormikError();
  const { setAutomaticPricesState } = useContext(NewShipmentContext);
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { loadingContactValidationSchema } = useNewShipmentValidation();

  const name: ShipmentAddressType = type;
  const nameAlternative: ShipmentAddressAlternativeType = `${type}Alternative` as ShipmentAddressAlternativeType;

  const addressAlternativeValue = isPriceRequest ? undefined : formik.values[nameAlternative];

  const { country, postCode }: LoadingContact = get(formik.values, name) ?? {};

  const [alternativeAddressDialogOpen, setAlternativeAddressDialogOpen] = useState(false);
  const [alternativeAddressEditting, setAlternativeAddressEditting] = useState(false);

  // When we edit the alternative address, we save the copy of existing value so that we can revert to this state
  // when the editting is canceled. We need it, because the form will update the values in formik in real-time.
  const [alternativeAddressCopy, setAlternativeAddressCopy] = useState();

  const [showMoreInformation, setShowMoreInformation] = useState(!isPriceRequest);

  const typeTranslation = type === 'sender' ? 'SENDER' : 'RECEIVER';
  const nameUseAlternative: ShipmentAddressAlternativeUseType =
    type === 'sender' ? 'useSenderAlternative' : 'useReceiverAlternative';
  const onAddressAutocomplete = useCallback(() => {
    setShowMoreInformation(true);
  }, [setShowMoreInformation]);

  const clearAllFields = () => {
    formik.setFieldValue(name, emptyLoadingContact);
    if (!isPriceRequest) {
      formik.setFieldValue(nameAlternative, emptyLoadingContact);
    }
  };

  const ensureAlternativeAddressExists = () => {
    if (!addressAlternativeValue) {
      formik.setFieldValue(nameAlternative, { ...emptyLoadingContact });
    }
  };

  const resetAlternativeAddress = () => {
    formik.setFieldValue(nameAlternative, undefined);
    formik.setFieldValue(nameUseAlternative, false);
    formik.setFieldError(nameAlternative, undefined);
    formik.setFieldTouched(nameAlternative, undefined);
    formik.setFieldTouched(nameUseAlternative, undefined);
  };

  const revertAlternativeAddress = () => {
    formik.setFieldValue(nameAlternative, alternativeAddressCopy);
    setAlternativeAddressCopy(undefined);
  };

  const toggleAlternativeAddressDialog = () => {
    if (!alternativeAddressDialogOpen) {
      ensureAlternativeAddressExists();
      setAlternativeAddressCopy(
        addressAlternativeValue ? { ...addressAlternativeValue } : undefined,
      );
    }

    setAlternativeAddressDialogOpen(!alternativeAddressDialogOpen);
  };

  const toggleShowMoreInformation = () => {
    setShowMoreInformation(!showMoreInformation);
  };

  const onAlternativeAddressSave = () => {
    try {
      validateYupSchema<LoadingContact>(
        addressAlternativeValue ?? {},
        loadingContactValidationSchema,
        true,
      );
    } catch (err) {
      const alternativeAddressErrors = yupToFormErrors(err);
      formik.setErrors({
        ...formik.errors,
        [nameAlternative]: alternativeAddressErrors,
      });

      const alternativeAddressTouched = mapValues(alternativeAddressErrors, (v) => !!v);
      formik.setTouched({
        ...formik.touched,
        [nameAlternative]: alternativeAddressTouched,
      });
      formik.setFieldValue(nameUseAlternative, true);
      return;
    }

    formik.validateField(nameAlternative);

    if (alternativeAddressEditting) {
      setAlternativeAddressEditting(false);
    }
    toggleAlternativeAddressDialog();
  };

  const onAlternativeAddressCancel = () => {
    if (!alternativeAddressEditting) {
      resetAlternativeAddress();
    } else {
      setAlternativeAddressEditting(false);
    }

    revertAlternativeAddress();

    toggleAlternativeAddressDialog();
  };

  const onAlternativeAddressEdit = () => {
    toggleAlternativeAddressDialog();
    setAlternativeAddressEditting(true);
  };

  const alternativeAddressVisible =
    !isObjectEmpty(addressAlternativeValue) && !alternativeAddressDialogOpen;

  const alternativeAddressComponent = !alternativeAddressVisible ? (
    <Typography
      link
      variant="caption"
      className={clsx(isTemplate && commonClasses.mt6, isTemplate && commonClasses.dFlex)}
      onClick={toggleAlternativeAddressDialog}
    >
      {t(`SHIPMENT.ADDRESSES.${typeTranslation}.ADD_AN_ALTERNATIVE_ADDRESS`)}
    </Typography>
  ) : (
    addressAlternativeValue &&
    !isObjectEmpty(addressAlternativeValue) && (
      <Box marginTop={5}>
        <ShipmentAddressAccordion
          type={type}
          address={addressAlternativeValue}
          onEdit={onAlternativeAddressEdit}
          onDelete={resetAlternativeAddress}
        />
      </Box>
    )
  );

  const showMoreInformationComponent = (
    <Typography
      link
      variant="caption"
      onClick={toggleShowMoreInformation}
      marginTop={6}
      textAlign="center"
      component="p"
    >
      {t(
        showMoreInformation
          ? 'SHIPMENT.ADDRESSES.CLOSE_MORE_INFORMATION'
          : 'SHIPMENT.ADDRESSES.ADD_MORE_INFORMATION',
      )}
    </Typography>
  );

  useEffect(() => {
    setAutomaticPricesState(AutomaticPricesState.InProgress);
  }, [setAutomaticPricesState, country, postCode]);

  useEffect(() => {
    if (isError(name) && !showMoreInformation) {
      setShowMoreInformation(true);
    }
  }, [formik]);

  const chooseOffice = (addressItem: Address) => {
    clearAllFields();
    formik.setFieldValue(`${name}.addressLine2`, addressItem.addressLine2);
    formik.setFieldValue(`${name}.addressLine1`, addressItem.addressLine1);
    formik.setFieldValue(`${name}.city`, addressItem.city);
    formik.setFieldValue(`${name}.postCode`, addressItem.postalCode);
    formik.setFieldValue(`${name}.country`, addressItem.country);
    formik.setFieldValue(`${name}.companyName`, addressItem.company);
    formik.setFieldValue(`${name}.contactPersonName`, addressItem.contact);
    formik.setFieldValue(`${name}.email`, addressItem.contactEmail);
    formik.setFieldValue(`${name}.phone`, addressItem.contactPhone);
    formik.setFieldValue(`${name}.notes`, addressItem.notes);
    formik.setFieldValue(`${name}.id`, addressItem.addressId ?? 0);
  };

  return (
    <Box
      width="100%"
      borderRadius={16}
      paddingTop={8}
      paddingX={6}
      paddingBottom={10}
      bgcolor={theme.palette.custom.veryLightGrayAlt2}
      {...restProps}
    >
      <Typography variant="subtitle2" fontWeight="extraBold">
        {t(
          type === 'sender'
            ? 'SHIPMENT.ADDRESSES.WHERE_TO_PICK_UP_FROM'
            : 'SHIPMENT.ADDRESSES.WHERE_TO_DELIVER',
        )}
      </Typography>

      {offices.length > 0 && (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={4}>
            <Typography variant="h6">{t('COMPANY.OFFICES')}:</Typography>
            {/* <Typography link variant="caption">
          {t('SHIPMENT.ADDRESSES.CHOOSE_FROM_ADDRESS_BOOK')}
        </Typography> */}
          </Box>

          <Box display="flex" marginTop={2}>
            {offices.map((addressItem, index) => {
              const isLast = index >= offices.length - 1;
              return (
                <Chip
                  onClick={() => chooseOffice(addressItem)}
                  key={generateKey(index, `recently_used_address_${type}`)}
                  label={addressItem.addressName}
                  className={clsx(!isLast && commonClasses.mr2)}
                />
              );
            })}
          </Box>
        </>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={3}
        marginTop={6}
      >
        <Typography variant="h6">{t('SHIPMENT.ADDRESSES.ADD_MANUALLY')}:</Typography>
        <Typography link variant="caption" onClick={clearAllFields}>
          {t('SHIPMENT.ADDRESSES.CLEAR_ALL_FIELDS')}
        </Typography>
      </Box>

      <AddressAutocompleteContext.Provider value={onAddressAutocomplete}>
        <ShipmentAddressFields
          name={name}
          type={type}
          typeTranslation={typeTranslation}
          showMoreInformation={showMoreInformation}
          isPriceRequest={isPriceRequest}
          isTemplate={isTemplate}
        />
      </AddressAutocompleteContext.Provider>

      {!isPriceRequest && alternativeAddressComponent}

      {isPriceRequest && showMoreInformationComponent}

      {!isPriceRequest && (
        <Dialog
          title={t('SHIPMENT.ADDRESSES.ADD_AN_ALTERNATIVE_ADDRESS')}
          maxWidth="md"
          open={alternativeAddressDialogOpen}
          handleClose={onAlternativeAddressCancel}
          actionAcceptLabel={t(
            alternativeAddressEditting
              ? 'SHIPMENT.ADDRESSES.UPDATE_ADDRESS'
              : 'SHIPMENT.ADDRESSES.ADD_ADDRESS',
          )}
          actionCancelLabel={t('CANCEL')}
          onActionAccept={onAlternativeAddressSave}
          onActionCancel={onAlternativeAddressCancel}
        >
          {alternativeAddressDialogOpen && (
            <ShipmentAddressFields
              name={nameAlternative}
              isAlternative
              type={type}
              isTemplate={isTemplate}
              typeTranslation={typeTranslation}
            />
          )}
        </Dialog>
      )}
    </Box>
  );
};

export default ShipmentAddressEditable;
