import { DatatableAddressBook, Address } from 'shared/types/addressBook/addressBook';
import { LoadingContact } from 'shared/types/loadingContact';

export const malformedRowsParser = (str: string): Address => {
  const addressInfo = str.split(',');
  return {
    country: addressInfo[6] ?? undefined,
    city: addressInfo[5] ?? undefined,
    addressLine1: addressInfo[1] ?? undefined,
    addressLine2: addressInfo[2] ?? undefined,
    addressName: '',
    contactEmail: addressInfo[9] ?? undefined,
    contactReference: '',
    id: 0,
    kmkRegistrationNumber: '',
    lat: '',
    lng: '',
    notes: '',
    postalCode: addressInfo[4] ?? undefined,
    street: '',
    company: addressInfo[0] ?? undefined,
    contact: addressInfo[7] ?? undefined,
    contactPhone: addressInfo[8] ?? undefined,
    isPrimary: false,
  };
};
export const addressBookParser = (addresses: Address[]): DatatableAddressBook[] =>
  addresses.map(
    ({ id, addressName, country, city, company, contact, contactPhone, addressLine1, street }) => ({
      id,
      addressName,
      country,
      city,
      company,
      contact,
      contactPhone,
      street: addressLine1 ?? street,
    }),
  );

export const mapAddressToLoadingContact = (address: Address): LoadingContact => ({
  addressLine1: address.addressLine1,
  addressLine2: address.addressLine2,
  city: address.city,
  companyName: address.company,
  contactPersonName: address.contact,
  country: address.country,
  email: address.contactEmail,
  id: address.id,
  lat: address.lat,
  lng: address.lng,
  notes: address.notes,
  phone: address.contactPhone,
  postCode: address.postalCode,
  vat: address.kmkRegistrationNumber,
});
