import React, { ReactElement, FC, useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrices } from 'shared/services/prices';
import Box from '@material-ui/core/Box';
import qs from 'qs';
import mapValues from 'lodash/mapValues';
import { useBeforeunload } from 'react-beforeunload';
import { useDebouncedCallback } from 'use-debounce';

import {
  Button,
  PageView,
  ShipmentAddressesStep,
  ShipmentItemsStep,
  ShipmentReferenceNumberStep,
  Typography,
  FileDropZone,
  Loader,
  Dialog,
  ShipmentConfirmationDialog,
  ShipmentDateStep,
  AddShipmentStep,
  ShipmentPriceStep,
} from 'components';

import {
  NewShipmentPayload,
  ShipmentDocument,
  ShipmentPayload,
  ShipmentType,
} from 'shared/types/shipments/shipments';
import { BaseProps } from 'shared/types';
import { Form, Formik, FormikProps, validateYupSchema, yupToFormErrors } from 'formik';
import { materialDialogCleanup } from 'shared/functions/materialDialogCleanup';
import { scrollToFirstInvalid } from 'shared/functions/scrollToFirstInvalid';
import { performInNextCycle } from 'shared/functions/performInNextCycle';
import { emptyLoadingContact } from 'shared/constants/shipments/shipmentAddress';
import { useShipments } from 'shared/services/shipments';
import { useNewShipmentValidation } from 'shared/hooks/useNewShipmentValidation';
import { useSystemAlert } from 'shared/hooks/useSystemAlert';
import { routeShipments, notFound as notFoundRoute } from 'routes';
import { useHistory, useLocation, useParams, Prompt } from 'react-router-dom';
import { useAddressBook } from 'shared/services/addressBook';
import { LoadingContact } from 'shared/types/loadingContact';
import { useNotFound } from 'shared/hooks/useNotFound';
import { usePriceRequests } from 'shared/services/priceRequests';
import { generateDate } from 'shared/functions/date';
import { PriceRequestOfferedPrice } from 'shared/types/priceRequests/priceRequests';
import { useUser } from 'shared/hooks/useUser';
import { useFeatures } from 'shared/hooks/useFeatures';
import { useTemplates } from 'shared/services/templates';
import { TemplateDetailsPayload } from 'shared/types/templates/templates';
import ShipmentTypeStep from 'components/Shipment/NewShipment/ShipmentTypeStep/ShipmentTypeStep';

type ShipmentProps = BaseProps;

type RouteParams = { shipmentId: string; templateId: string };

const emptyShipmentData: NewShipmentPayload = {
  earliestArrivalTime: '',
  earliestPickupTime: '',
  latestArrivalTime: '',
  latestPickupTime: '',
  earliestArrivalDate: null,
  earliestPickupDate: null,
  latestArrivalDate: null,
  latestPickupDate: null,
  receiver: { ...emptyLoadingContact },
  sender: { ...emptyLoadingContact },
  shipmentDetailsRows: [],
  tailLiftTruck: false,
  tailLiftTruckDelivery: false,
  tenantId: null,
  useReceiverAlternative: false,
  useSenderAlternative: false,
  type: ShipmentType.Sender,
  isFixedPickupDate: false,
  isFixedArrivalDate: false,
  priceRequestId: null,
};

export enum AutomaticPricesState {
  Idle,
  CantCalculate,
  InProgress,
}

export type NewShipmentContextData = {
  automaticPricesState: AutomaticPricesState;
  setAutomaticPricesState: React.Dispatch<React.SetStateAction<AutomaticPricesState>>;
};

const defaultNewShipmentData: NewShipmentContextData = {
  automaticPricesState: AutomaticPricesState.Idle,
  setAutomaticPricesState: () => {},
};

export const NewShipmentContext = React.createContext<NewShipmentContextData>(
  defaultNewShipmentData,
);

const calculatePricesDelay = 3000;

const NewShipment: FC<ShipmentProps> = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const notFound = useNotFound();
  const { priceEngine: priceEngineFeature } = useFeatures();
  const { shipmentId } = useParams<RouteParams>();
  const location = useLocation();
  const { templateId } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { priceRequestId } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { copyShipmentId } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const formRef = useRef<FormikProps<NewShipmentPayload> | null>(null);
  const [documents, setDocuments] = useState<ShipmentDocument[]>([]);
  const [automaticPricesState, setAutomaticPricesState] = useState<AutomaticPricesState>(
    AutomaticPricesState.Idle,
  );

  const [newShipmentContextValue, setNewShipmentContextValue] = useState<NewShipmentContextData>({
    automaticPricesState,
    setAutomaticPricesState,
  });

  const { fetchPrices } = usePrices();

  const { showSystemSuccessMessage } = useSystemAlert();

  const { profile: userProfile } = useUser();
  const { saveShipment, fetchShipment, saveUnSavedShipment } = useShipments();
  const { fetchTemplate } = useTemplates();
  const { fetchPriceRequest } = usePriceRequests();
  const { fetchCompanyAddressForShipment } = useAddressBook();
  const [isPending, setIsPending] = useState(true);
  const [unSavedDataId, setUnSavedDataId] = useState('');
  const [initialShipmentData, setInitialShipmentData] = useState<NewShipmentPayload>();

  const { confirmValidationSchema, draftValidationSchema } = useNewShipmentValidation();
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState(false);
  const [shipmentConfirmationDialogOpen, setShipmentConfirmationDialogOpen] = useState(false);

  const validateForAutomaticPrices = (offeredPrices?: PriceRequestOfferedPrice[]): void => {
    if (!offeredPrices || !offeredPrices.length) {
      throw new Error("can't calculate automatic prices");
    }
    if (formRef.current) {
      const { shipmentDetailsRows = [] } = formRef.current.values;
      shipmentDetailsRows.forEach((item) => {
        if (item.coldFrozen || item.dangerousGoods || item.delicateGoods) {
          throw new Error("can't calculate automatic prices");
        }
      });
    }
  };

  const canCalculatePrices = () => {
    if (formRef.current && !priceRequestId) {
      const {
        shipmentDetailsRows = [],
        receiver,
        sender,
        isFixedPickupDate,
        isFixedArrivalDate,
      } = formRef.current.values;
      if (
        !shipmentDetailsRows.length ||
        !receiver?.country ||
        !receiver.postCode ||
        !sender?.country ||
        !sender.postCode ||
        isFixedPickupDate ||
        isFixedArrivalDate
      ) {
        return false;
      }

      return shipmentDetailsRows.some((item) => item.height && item.weight && item.ldm);
    }
    return false;
  };

  const parseOfferedPrices = (
    offeredPrices?: PriceRequestOfferedPrice[],
    selectedPriceOffer?: string | number | null,
  ) => {
    // If the price doesn't have id, fake it for selection needs
    let prices = offeredPrices?.map((price, index) => ({ ...price, id: price.id ?? index + 1 }));
    let selected;

    if (prices?.length) {
      if (selectedPriceOffer !== undefined && selectedPriceOffer !== null) {
        const selectedPrice = prices.find((price) => price.id === +selectedPriceOffer);
        if (selectedPrice) {
          selected = selectedPrice.id.toString();
          prices = [selectedPrice];
        }
      }
    }

    return {
      offeredPrices: prices,
      selectedPriceOffer: selected,
    };
  };

  const handleSubmitUnSavedForm = async (payload: NewShipmentPayload) => {
    const unsavedShipmentId = await saveUnSavedShipment({ payload, id: unSavedDataId });
    if (unsavedShipmentId) setUnSavedDataId(unsavedShipmentId.payload ?? '');
  };

  const calculatePrices = async () => {
    setNewShipmentContextValue((currentShipmentContextValue) => ({
      ...currentShipmentContextValue,
      automaticPricesState,
      setAutomaticPricesState,
    }));

    if (automaticPricesState === AutomaticPricesState.InProgress && formRef.current) {
      try {
        if (canCalculatePrices()) {
          if (!priceRequestId) {
            formRef.current.setFieldValue('selectedPriceOffer', null);
            formRef.current.setFieldValue('offeredPrices', []);
          }
          const { payload } = await fetchPrices(formRef.current.values);
          const { offeredPrices, selectedPriceOffer } =
            parseOfferedPrices(
              payload?.offeredPrices,
              formRef.current?.values.selectedPriceOffer,
            ) ?? {};
          if (offeredPrices) {
            formRef.current.setFieldValue('offeredPrices', offeredPrices);
            formRef.current.setFieldValue('selectedPriceOffer', selectedPriceOffer);
          }
          if (formRef.current?.values) await handleSubmitUnSavedForm(formRef.current.values);
          validateForAutomaticPrices(offeredPrices);
        } else if (priceRequestId) {
          formRef.current.setFieldValue(
            'selectedPriceOffer',
            formRef?.current.values.selectedPriceOffer,
          );
        } else {
          formRef.current.setFieldValue('selectedPriceOffer', null);
          formRef.current.setFieldValue('offeredPrices', []);
        }
        setAutomaticPricesState(AutomaticPricesState.Idle);
      } catch (error) {
        setAutomaticPricesState(AutomaticPricesState.CantCalculate);
      }
    }
  };

  const debouncedCalculatePrices = useDebouncedCallback(calculatePrices, calculatePricesDelay);

  useEffect(() => {
    if (userProfile?.showPriceRates && priceEngineFeature) {
      debouncedCalculatePrices.callback();

      setNewShipmentContextValue((currentShipmentContextValue) => ({
        ...currentShipmentContextValue,
        automaticPricesState,
      }));
    }
  }, [automaticPricesState, setAutomaticPricesState, initialShipmentData]);

  const toggleCancelConfirmationDialog = () =>
    setShowCancelConfirmationDialog(!showCancelConfirmationDialog);

  const toggleShipmentConfirmationDialog = () => {
    setShipmentConfirmationDialogOpen(!shipmentConfirmationDialogOpen);
  };

  const setDataFromTemplate = (payload: TemplateDetailsPayload | undefined) => {
    if (payload) {
      const data: NewShipmentPayload = {
        ...emptyShipmentData,
        receiver: payload.receiver,
        sender: payload.sender,
        receiverAlternative: payload.receiverAlternative,
        senderAlternative: payload.senderAlternative,
        shipmentDetailsRows: payload.shipmentDetailsRows,
        tailLiftTruck: payload.tailLiftTruck,
        tailLiftTruckDelivery: payload.tailLiftTruckDelivery,
        useReceiverAlternative: payload.useReceiverAlternative,
        useSenderAlternative: payload.useSenderAlternative,
        type: payload.type,
        otherInstructions: payload.otherInstructions,
        templateName: payload.templateName,
        templateId: templateId ? parseInt(templateId?.toString(), 10) : undefined,
      };
      setInitialShipmentData(data);
    } else {
      setInitialShipmentData({ ...emptyShipmentData });
    }
  };

  const getShipment = async (id: string) => {
    try {
      const response = await fetchShipment({ id: +id });
      if (response.payload) {
        return response.payload;
      }
      // TODO: this endpoint returns empty payload instead of 404, therefore we have to do error handling here as well
      notFound();
    } catch (error) {
      notFound();
    }
    return { ...emptyShipmentData };
  };
  const getPriceRequest = async (id: string) => {
    try {
      const response = await fetchPriceRequest({ id: +id });
      if (response.payload) {
        return response.payload;
      }
      // TODO: this endpoint returns empty payload instead of 404, therefore we have to do error handling here as well
      notFound();
    } catch (error) {
      notFound();
    }
    return { ...emptyShipmentData };
  };

  const promptText = t('SHIPMENT.YOU_HAVE_UNSAVED_CHANGES');

  const fileDropZoneRef = useRef<{
    uploadAcceptedFiles: (objectId: number) => void;
    getUploadedFiles: (objectId: number) => void;
  }>(null);

  const loadUploadedFiles = (id: number) => {
    fileDropZoneRef?.current?.getUploadedFiles(id);
  };

  useEffect(() => {
    setIsPending(true);
    if (shipmentId) {
      getShipment(shipmentId).then((payload) => {
        setInitialShipmentData(payload);
        loadUploadedFiles(parseInt(shipmentId, 10));
      });
    } else if (templateId) {
      fetchTemplate({ id: parseInt(templateId.toString(), 10) }).then((payload) => {
        setDataFromTemplate(payload.payload);
      });
    } else if (copyShipmentId) {
      getShipment(copyShipmentId.toString()).then((payload) => {
        const data: NewShipmentPayload = {
          ...emptyShipmentData,
          receiver: payload.receiver,
          sender: payload.sender,
          receiverAlternative: payload.receiverAlternative,
          senderAlternative: payload.senderAlternative,
          shipmentDetailsRows: payload.shipmentDetailsRows,
          tailLiftTruck: payload.tailLiftTruck,
          tailLiftTruckDelivery: payload.tailLiftTruckDelivery,
          useReceiverAlternative: payload.useReceiverAlternative,
          useSenderAlternative: payload.useSenderAlternative,
          type: payload.type,
          otherInstructions: payload.otherInstructions,
        };
        setInitialShipmentData(data);
      });
    } else if (priceRequestId) {
      getPriceRequest(priceRequestId.toString()).then((payload) => {
        const data: NewShipmentPayload = {
          ...emptyShipmentData,
          receiver: payload.receiver,
          sender: payload.sender,
          receiverAlternative: payload.receiverAlternative,
          senderAlternative: payload.senderAlternative,
          shipmentDetailsRows: payload.shipmentDetailsRows,
          tailLiftTruck: payload.tailLiftTruck,
          tailLiftTruckDelivery: payload.tailLiftTruckDelivery,
          useReceiverAlternative: payload.useReceiverAlternative,
          useSenderAlternative: payload.useSenderAlternative,
          type: payload.type,
          otherInstructions: payload.otherInstructions,
          earliestArrivalDate: payload.earliestArrivalDate,
          latestArrivalDate: payload.latestArrivalDate,
          earliestPickupDate: payload.earliestPickupDate,
          latestPickupDate: payload.latestPickupDate,
          latestPickupTime: payload.latestPickupTime,
          earliestArrivalTime: payload.earliestArrivalTime,
          earliestPickupTime: payload.earliestPickupTime,
          latestArrivalTime: payload.latestArrivalTime,
          isFixedArrivalDate: payload.isFixedArrivalDate,
          isFixedPickupDate: payload.isFixedPickupDate,
          priceRequestId: parseInt(priceRequestId.toString(), 10),
          ...parseOfferedPrices(payload.offeredPrices, payload.selectedPriceOffer),
        };
        setInitialShipmentData(data);
      });
    } else {
      try {
        fetchCompanyAddressForShipment().then((address) => {
          const sender: LoadingContact = {
            addressLine1: address?.payload?.addressLine1,
            city: address?.payload?.city,
            companyName: address?.payload?.companyName,
            country: address?.payload?.country,
            postCode: address?.payload?.postCode,
            email: address?.payload?.email,
            phone: address?.payload?.phone,
            contactPersonName: address?.payload?.contactPersonName,
          };
          const data: NewShipmentPayload = {
            ...emptyShipmentData,
            sender,
          };
          setInitialShipmentData(data);
        });
      } catch (error) {
        setInitialShipmentData({ ...emptyShipmentData });
      }
    }
    setIsPending(false);
  }, [shipmentId, templateId, priceRequestId]);

  useBeforeunload(() => t('SHIPMENT.YOU_HAVE_UNSAVED_CHANGES'));

  const title = (
    <Box display="flex" alignItems="center">
      {t(shipmentId ? 'EDIT_SHIPMENT_DRAFT_NO' : 'ADD_NEW_SHIPMENT').replace(
        '{0}',
        initialShipmentData?.number || '',
      )}
    </Box>
  );

  const uploadFiles = (id: number) => {
    fileDropZoneRef?.current?.uploadAcceptedFiles(id);
  };

  const onCancel = () => {
    materialDialogCleanup();
    history.replace(routeShipments);
  };

  const openShipment = (id: number) => {
    materialDialogCleanup();
    history.replace(`${routeShipments}/${id}`);
  };

  const handleSubmit = async (payload: NewShipmentPayload, draft: boolean) => {
    if (isPending) return;
    setIsPending(true);

    try {
      // eslint-disable-next-line no-param-reassign
      payload.autoSavedObject = unSavedDataId;
      // eslint-disable-next-line no-param-reassign
      payload.useReceiverAlternative = !!payload.receiverAlternative?.addressLine1;
      // eslint-disable-next-line no-param-reassign
      payload.useSenderAlternative = !!payload.senderAlternative?.addressLine1;
      const result = await saveShipment({ payload, draft });
      const newShipmentId = payload.id ?? result.payload;

      if (result.isSuccessful && newShipmentId) {
        await uploadFiles(newShipmentId);
        if (!draft) {
          openShipment(newShipmentId);
        } else {
          history.replace(`${routeShipments}/new-shipment/${newShipmentId}`);
          history.push(routeShipments);
          showSystemSuccessMessage(t('SHIPMENT.SAVE_AS_DRAFT_SUCCESS'));
        }
      }
    } finally {
      setIsPending(false);
    }
  };

  const submitForm = async (draft: boolean) => {
    if (formRef.current) {
      try {
        await validateYupSchema<NewShipmentPayload>(
          formRef.current.values ?? {},
          draft ? draftValidationSchema : confirmValidationSchema,
          true,
        );

        // Make sure that no fields are marked as errors if the validation has passed
        formRef.current.setErrors({});

        if (draft) {
          handleSubmit(formRef.current.values, draft);
        } else {
          toggleShipmentConfirmationDialog();
        }
      } catch (err) {
        const formErrors = yupToFormErrors(err);
        const touchedValues = mapValues(formErrors, (v) => !!v);

        formRef.current.setErrors(formErrors);
        formRef.current.setTouched({ ...touchedValues });

        performInNextCycle(scrollToFirstInvalid);
      }
    }
  };

  const onSaveAsDraft = () => {
    submitForm(true);
  };

  const onSave = () => {
    submitForm(false);
  };

  const onFilesStateChanged = (files: ShipmentDocument[]) => {
    const newDocuments: ShipmentDocument[] = files.map(({ fileName, file, size }) => ({
      fileName: file?.name ?? fileName ?? '',
      size: file?.size ?? size,
      allowDelete: false,
      id: -1,
      url: '',
      createdOn: generateDate(new Date()),
      file: undefined,
      allowToggleLocking: false,
      isLocked: false,
    }));
    setDocuments(newDocuments);
  };

  // Formik requires onSubmit function to be defined, however we don't use it,
  // hence we can define an empty one
  const emptyFunction = useCallback(() => {}, []);

  const newShipmentData = formRef.current?.values as ShipmentPayload;

  const showPrices = !!(
    (userProfile?.showPriceRates && priceEngineFeature) ||
    formRef.current?.values.offeredPrices?.length
  );
  const allSteps = showPrices ? 7 : 6;

  return (
    <PageView
      title={title}
      boxProps={{ display: 'flex', flexDirection: 'column', paddingLeft: 8, paddingTop: 8 }}
    >
      {isPending && <Loader cover />}
      {initialShipmentData ? (
        <Formik
          enableReinitialize
          initialValues={initialShipmentData}
          onSubmit={emptyFunction}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={confirmValidationSchema}
          innerRef={formRef}
        >
          {() => (
            <Form>
              <NewShipmentContext.Provider value={newShipmentContextValue}>
                <AddShipmentStep step={1} allSteps={allSteps}>
                  <ShipmentTypeStep />
                </AddShipmentStep>
                <AddShipmentStep step={2} allSteps={allSteps}>
                  <ShipmentItemsStep />
                </AddShipmentStep>
                <AddShipmentStep step={3} allSteps={allSteps}>
                  <ShipmentAddressesStep />
                </AddShipmentStep>
                <AddShipmentStep step={4} allSteps={allSteps}>
                  <ShipmentDateStep />
                </AddShipmentStep>
                <AddShipmentStep step={5} allSteps={allSteps}>
                  <FileDropZone
                    object="Shipment"
                    ref={fileDropZoneRef}
                    hideTitle={false}
                    onFilesStateChanged={onFilesStateChanged}
                    id={parseInt(shipmentId, 10)}
                  />
                </AddShipmentStep>
                <AddShipmentStep
                  step={6}
                  allSteps={allSteps}
                  hideBorder={!showPrices}
                  paddingBottom={2.5}
                >
                  <ShipmentReferenceNumberStep />
                </AddShipmentStep>
                {showPrices && (
                  <AddShipmentStep
                    step={7}
                    allSteps={allSteps}
                    hideBorder
                    paddingBottom={2.5}
                    marginBottom={18}
                  >
                    <ShipmentPriceStep
                      isPriceRequest={false}
                      disablePriceDeselect={!!priceRequestId}
                    />
                  </AddShipmentStep>
                )}
              </NewShipmentContext.Provider>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Typography
                  variant="body2"
                  link
                  onClick={toggleCancelConfirmationDialog}
                  marginRight={6}
                >
                  {t('CANCEL')}
                </Typography>
                {initialShipmentData?.status !== 3 && (
                  <Typography variant="body2" link onClick={onSaveAsDraft} marginRight={6}>
                    {t('SHIPMENT.SAVE_AS_DRAFT')}
                  </Typography>
                )}
                <Button variant="contained" color="primary" onClick={onSave} disabled={isPending}>
                  {t('SHIPMENT.SEND_SHIPMENT')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      ) : (
        <Box height={500} />
      )}
      <Dialog
        title={t('SHIPMENT.CANCEL_CONFIRMATION_DIALOG_TEXT')}
        maxWidth="sm"
        open={showCancelConfirmationDialog}
        handleClose={toggleCancelConfirmationDialog}
        actionCancelLabel={t('NO')}
        actionAcceptLabel={t('YES')}
        onActionCancel={toggleCancelConfirmationDialog}
        onActionAccept={onCancel}
      />
      <Prompt
        message={(nextLocation, action) => {
          if (action === 'PUSH' && !shipmentId && formRef.current) {
            handleSubmitUnSavedForm(formRef.current.values);
          }

          return nextLocation.pathname.startsWith(routeShipments) ||
            nextLocation.pathname.startsWith(notFoundRoute)
            ? true
            : promptText;
        }}
      />
      {newShipmentData && (
        <ShipmentConfirmationDialog
          open={shipmentConfirmationDialogOpen}
          handleClose={toggleShipmentConfirmationDialog}
          documents={documents}
          shipment={newShipmentData}
          onActionAccept={() =>
            formRef.current ? handleSubmit(formRef.current.values, false) : null
          }
          onActionCancel={toggleCancelConfirmationDialog}
        />
      )}
    </PageView>
  );
};

export default NewShipment;
