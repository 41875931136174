import { useFetchApi } from 'shared/functions/fetchApi';
import {
  CompanyAddress,
  CompanyProfileApiRequest,
  CompanyProfilePayload,
  CompanyAddressApiRequest,
  CompanyFetchAddressApiRequest,
  CompanyDetails,
  CompanyDetailsApiRequest,
  CompanyFetchDetailsApiRequest,
  CompanyContact,
  CompanyFetchContactApiRequest,
  CompanyContactApiRequest,
  CompanyOfficeAddressApiRequest,
} from 'shared/types/company/company';
import { ApiResponse } from 'shared/types/api';
import { Address } from '../types/addressBook/addressBook';

type UseCompanyReturnType = {
  fetchCompanyProfile: (
    request: CompanyProfileApiRequest,
  ) => Promise<ApiResponse<CompanyProfilePayload>>;

  saveCompanyAddress: (request: CompanyAddressApiRequest) => Promise<ApiResponse<boolean>>;
  saveCompanyOfficeAddress: (
    request: CompanyOfficeAddressApiRequest,
  ) => Promise<ApiResponse<boolean>>;
  fetchCompanyAddress: (
    request: CompanyFetchAddressApiRequest,
  ) => Promise<ApiResponse<CompanyAddress>>;

  saveCompanyDetails: (request: CompanyDetailsApiRequest) => Promise<ApiResponse<boolean>>;
  fetchCompanyDetails: (
    request: CompanyFetchDetailsApiRequest,
  ) => Promise<ApiResponse<CompanyDetails>>;

  saveCompanyContact: (request: CompanyContactApiRequest) => Promise<ApiResponse<boolean>>;
  fetchCompanyContact: (
    request: CompanyFetchContactApiRequest,
  ) => Promise<ApiResponse<CompanyContact>>;

  fetchCompanyOffices: (request: CompanyProfileApiRequest) => Promise<ApiResponse<Address[]>>;

  fetchCompanyOffice: (request: CompanyProfileApiRequest) => Promise<ApiResponse<Address>>;
  deleteCompanyOffice: (request: CompanyProfileApiRequest) => Promise<ApiResponse<boolean>>;
};

export const useCompany = (): UseCompanyReturnType => {
  const { fetchApi } = useFetchApi();

  const fetchCompanyProfile = async ({
    id,
  }: CompanyProfileApiRequest): Promise<ApiResponse<CompanyProfilePayload>> => {
    const response = await fetchApi(`/api/booking_portal/company/${id}`);
    return response.json();
  };

  const saveCompanyAddress = async ({
    id,
    payload,
  }: CompanyAddressApiRequest): Promise<ApiResponse<boolean>> => {
    const body = JSON.stringify(payload);
    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body,
    };

    const response = await fetchApi(`/api/booking_portal/company/${id}/address/`, params);
    return response.json();
  };

  const fetchCompanyAddress = async ({
    id,
    addressType,
  }: CompanyFetchAddressApiRequest): Promise<ApiResponse<CompanyAddress>> => {
    const response = await fetchApi(
      `/api/booking_portal/company/${id}/address?CompanyDataType=${addressType}`,
    );
    return response.json();
  };

  const saveCompanyDetails = async ({
    id,
    payload,
  }: CompanyDetailsApiRequest): Promise<ApiResponse<boolean>> => {
    const body = JSON.stringify(payload);
    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body,
    };

    const response = await fetchApi(`/api/booking_portal/company/${id}/info/`, params);
    return response.json();
  };

  const fetchCompanyDetails = async ({
    id,
    companyDataType,
  }: CompanyFetchDetailsApiRequest): Promise<ApiResponse<CompanyDetails>> => {
    const response = await fetchApi(
      `/api/booking_portal/company/${id}/info?CompanyDataType=${companyDataType}`,
    );
    return response.json();
  };

  const saveCompanyContact = async ({
    id,
    payload,
  }: CompanyContactApiRequest): Promise<ApiResponse<boolean>> => {
    const body = JSON.stringify(payload);
    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body,
    };
    const response = await fetchApi(`/api/booking_portal/company/${id}/contact/`, params);
    return response.json();
  };

  const fetchCompanyContact = async ({
    id,
    companyDataType,
  }: CompanyFetchContactApiRequest): Promise<ApiResponse<CompanyContact>> => {
    const response = await fetchApi(
      `/api/booking_portal/company/${id}/contact?CompanyDataType=${companyDataType}`,
    );
    return response.json();
  };

  const fetchCompanyOffices = async (): Promise<ApiResponse<Address[]>> => {
    const response = await fetchApi(`/api/company/offices/0`);
    return response.json();
  };

  const fetchCompanyOffice = async ({
    id,
  }: CompanyProfileApiRequest): Promise<ApiResponse<Address>> => {
    const response = await fetchApi(`/api/company/office/${id}`);
    return response.json();
  };
  const saveCompanyOfficeAddress = async ({
    id,
    payload,
  }: CompanyOfficeAddressApiRequest): Promise<ApiResponse<boolean>> => {
    const body = JSON.stringify(payload);
    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: id === 0 ? 'POST' : 'PUT',
      body,
    };

    const response = await fetchApi(`/api/company/offices`, params);
    return response.json();
  };
  const deleteCompanyOffice = async (request: CompanyProfileApiRequest) => {
    const response = await fetchApi(`/api/company/offices/${request.id}`, { method: 'DELETE' });
    return response.json();
  };
  return {
    fetchCompanyProfile,
    saveCompanyAddress,
    fetchCompanyAddress,
    fetchCompanyDetails,
    saveCompanyDetails,
    fetchCompanyContact,
    saveCompanyContact,
    fetchCompanyOffices,
    fetchCompanyOffice,
    saveCompanyOfficeAddress,
    deleteCompanyOffice,
  };
};
