import React, { ReactElement, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { theme } from 'theme';
import { useFormikContext } from 'formik';

import { ShipmentAddressEditable, SwapButton, Typography } from 'components';
import { BaseProps } from 'shared/types';
import { NewShipmentPayload, ShipmentAddress } from 'shared/types/shipments/shipments';
import { emptyLoadingContact } from 'shared/constants/shipments/shipmentAddress';
import { useCompany } from 'shared/services/company';
import { Address } from 'shared/types/addressBook/addressBook';

type ShipmentAddressesStepProps = {
  isPriceRequest?: boolean;
  isTemplate?: boolean;
} & BaseProps;

const useStyles = makeStyles({
  asterisk: {
    color: theme.palette.custom.darkCyan,
  },
});

const ShipmentAddressesStep: FC<ShipmentAddressesStepProps> = ({
  isPriceRequest,
  isTemplate,
}): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const formik = useFormikContext<NewShipmentPayload>();
  const { fetchCompanyOffices } = useCompany();
  const [companyOffices, setCompanyOffices] = useState<Address[]>([]);
  useEffect(() => {
    const fetchOffices = async () => {
      const officesList = await fetchCompanyOffices({ id: 0 });
      if (officesList.payload) {
        setCompanyOffices(officesList.payload);
      } else {
        setCompanyOffices([]);
      }
    };
    fetchOffices();
  }, [formik.values]);

  const swapAddresses = () => {
    const receiverAddressData: ShipmentAddress = {
      address: formik.values.receiver ?? emptyLoadingContact,
      alternativeAddress: formik.values.receiverAlternative,
    };

    const senderAddressData: ShipmentAddress = {
      address: formik.values.sender ?? emptyLoadingContact,
      alternativeAddress: formik.values.senderAlternative,
    };

    formik.setFieldValue('sender', { ...receiverAddressData.address });
    formik.setFieldValue(
      'senderAlternative',
      receiverAddressData.alternativeAddress
        ? { ...receiverAddressData.alternativeAddress }
        : undefined,
    );

    formik.setFieldValue('receiver', { ...senderAddressData.address });
    formik.setFieldValue(
      'receiverAlternative',
      senderAddressData.alternativeAddress
        ? { ...senderAddressData.alternativeAddress }
        : undefined,
    );
  };

  return (
    <Box>
      <Typography variant="h5" marginBottom={1.5}>
        {!isTemplate
          ? t('SHIPMENT.ADDRESSES.ADD_ROUTE_ADDRESSES')
          : t('TEMPLATE.ADDRESSES.EDIT_ROUTE_ADDRESSES')}
      </Typography>
      {!isTemplate && (
        <Typography variant="body2">
          {t('SHIPMENT.ADDRESSES.REQUIRED_FIELDS_ARE_MARKED_WITH')}{' '}
          <span className={classes.asterisk}>*</span>
        </Typography>
      )}

      <Box width="100%" display="flex" marginTop={4.5} position="relative">
        <ShipmentAddressEditable
          type="sender"
          marginRight={6}
          isPriceRequest={isPriceRequest}
          isTemplate={isTemplate}
          offices={companyOffices}
        />

        <SwapButton
          position="absolute"
          top="calc(50% - 24px)"
          left="calc(50% - 24px)"
          onClick={swapAddresses}
        />

        <ShipmentAddressEditable
          type="receiver"
          isPriceRequest={isPriceRequest}
          isTemplate={isTemplate}
          offices={companyOffices}
        />
      </Box>
    </Box>
  );
};

export default ShipmentAddressesStep;
