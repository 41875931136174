import React, { ReactElement, FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Loader, PriceOffer, Typography } from 'components';

import { BaseProps } from 'shared/types';
import { useFormikContext } from 'formik';
import { NewShipmentPayload } from 'shared/types/shipments/shipments';
import { theme } from 'theme';
import { AutomaticPricesState, NewShipmentContext } from 'scenes/NewShipment/NewShipment';
import clsx from 'clsx';
import { PriceRequestOfferedPrice } from 'shared/types/priceRequests/priceRequests';

type ShipmentPriceStepProps = BaseProps & {
  isPriceRequest: boolean;
  disablePriceDeselect?: boolean;
  onClickOrderNow?: (offeredPrice?: PriceRequestOfferedPrice) => void;
};

export type ShipmentReferenceData = {
  notifyAboutShipment?: boolean;
  saveShipmentAsTemplate?: boolean;
};

const automaticPricesTexts = {
  [AutomaticPricesState.Idle]: 'SHIPMENT.NO_PRICES',
  [AutomaticPricesState.CantCalculate]: 'SHIPMENT.NO_AUTOMATIC_PRICES',
  [AutomaticPricesState.InProgress]: 'SHIPMENT.AUTOMATIC_PRICES_IN_PROGRESS',
};

const automaticPricesTextsForPriceRequest = {
  [AutomaticPricesState.Idle]: 'PRICE_REQUEST.NO_PRICES',
  [AutomaticPricesState.CantCalculate]: 'PRICE_REQUEST.NO_AUTOMATIC_PRICES',
  [AutomaticPricesState.InProgress]: 'SHIPMENT.AUTOMATIC_PRICES_IN_PROGRESS',
};

const ShipmentPriceStep: FC<ShipmentPriceStepProps> = ({
  isPriceRequest,
  onClickOrderNow,
  disablePriceDeselect,
}): ReactElement => {
  const { t } = useTranslation();

  const displayedOffersCount = 6;

  const { automaticPricesState } = useContext(NewShipmentContext);

  const formik = useFormikContext<NewShipmentPayload>();
  const offeredPrices = formik.values.offeredPrices ?? [];

  const [selectedOffer, setSelectedOffer] = useState<number | string | null | undefined>(
    formik.values.selectedPriceOffer ? +formik.values.selectedPriceOffer : null,
  );

  const onSelectedPriceOfferChanged = (id: number) => {
    if (disablePriceDeselect) {
      return;
    }
    formik.setErrors({ ...formik.errors, selectedPriceOffer: undefined });
    if (formik.values.selectedPriceOffer && formik.values.selectedPriceOffer === id) {
      formik.setFieldValue('selectedPriceOffer', undefined);
      setSelectedOffer(undefined);
      if (isPriceRequest) {
        formik.setFieldValue('acceptPriceForDays', false);
      }
      if (onClickOrderNow) {
        onClickOrderNow(undefined);
      }
    } else {
      formik.setFieldValue('selectedPriceOffer', id);
      if (onClickOrderNow) {
        const price = offeredPrices.filter((item) => item.id === id);
        if (price.length > 0) {
          onClickOrderNow(price[0]);
        }
      }
    }
  };

  useEffect(() => {
    setSelectedOffer(formik.values.selectedPriceOffer ? +formik.values.selectedPriceOffer : null);
  }, [formik.values.selectedPriceOffer]);

  const displayPrices =
    offeredPrices.length && automaticPricesState !== AutomaticPricesState.InProgress;

  return (
    <Box>
      <Typography variant="h5" marginBottom={1.5}>
        {!isPriceRequest
          ? t('SHIPMENT.YOUR_SHIPMENTS_PRICE')
          : t('PRICE_REQUEST.WE_CAN_OFFER_PRICES')}
      </Typography>
      <Typography variant="body2" fontWeight="medium" marginBottom={4}>
        {t('SHIPMENT.YOUR_SHIPMENTS_PRICE_DESC')}
      </Typography>

      <Box
        width={1133}
        borderRadius={16}
        bgcolor={theme.palette.custom.veryLightGrayAlt2}
        paddingX={displayPrices ? 6 : 32}
        paddingY={8}
        height={displayPrices ? undefined : 342}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent={displayPrices ? 'left' : 'center'}
          alignItems="center"
          width={1}
          height={1}
          className={clsx(!!formik.errors.selectedPriceOffer && 'Mui-error')}
        >
          {displayPrices ? (
            offeredPrices.slice(0, displayedOffersCount).map((offeredPrice, index) => (
              <Box
                key={offeredPrice.id}
                marginRight={index < displayedOffersCount - 1 ? 3.75 : 0}
                marginTop={index > 2 ? 4 : 0}
              >
                <PriceOffer
                  offeredPrice={offeredPrice}
                  onSelected={onSelectedPriceOfferChanged}
                  showError={!!formik.errors.selectedPriceOffer}
                  selected={selectedOffer === offeredPrice.id && offeredPrice.id !== undefined}
                  width={346}
                  hideCharacterization={isPriceRequest}
                  isPriceRequest={isPriceRequest}
                  onClickOrderNow={onClickOrderNow}
                />
              </Box>
            ))
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              {automaticPricesState === AutomaticPricesState.InProgress && (
                <Box marginBottom={3}>
                  <Loader />
                </Box>
              )}
              <Typography variant="h6" textAlign="center">
                {!isPriceRequest
                  ? t(automaticPricesTexts[automaticPricesState])
                  : t(automaticPricesTextsForPriceRequest[automaticPricesState])}
              </Typography>
            </Box>
          )}
        </Box>
        {!!formik.errors.selectedPriceOffer && (
          <Typography variant="caption" marginTop={1} customColor={theme.palette.custom.alertError}>
            {formik.errors.selectedPriceOffer}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ShipmentPriceStep;
